<template>
    <div v-if="current" :key="current.id" class="option-list-sections">
        <template v-for="(groupValue, groupName, index) in current.options">
            <SectionsGroup v-if="groups.includes(groupName)" :group="groupName" :key="groupName" :index="index">
                <div v-if="groupValue.component">
                    <FormItem v-bind="current.options[groupName].props">
                        <component
                            :is="current.options[groupName].component"
                            :name="groupName"
                            v-on="getFieldEvents(current.options[groupName].events)"
                            v-model="current.options[groupName].value"
                            v-bind="current.options[groupName].props"
                            @update-field="onUpdateFieldValue"
                            :disabled="disabledByHandler(current.options[groupName])"
                        />
                    </FormItem>
                </div>
                <template v-else>
                    <div
                        :key="key"
                        v-for="(field, key) in current.options[groupName]"
                        class="section-option-group__item-field"
                    >
                        <FormItem :name="key" v-bind="current.options[groupName][key].props">
                            <component
                                :is="field.component"
                                :name="key"
                                :group="groupName"
                                v-on="getFieldEvents(current.options[groupName][key].events)"
                                v-model="current.options[groupName][key].value"
                                v-bind="current.options[groupName][key].props"
                                :disabled="disabledByHandler(current.options[groupName], key)"
                                @update-field="onUpdateFieldValue"
                            />
                        </FormItem>
                    </div>
                </template>
            </SectionsGroup>
            <div v-else :key="groupName" class="section-option-group">
                <div class="section-option-group__heading">
                    <span class="text-subtitle">{{ $t(`builder.sectionOptions.groups.${groupName}`) }}</span>
                </div>
                <!-- for exanple: item per row component -->
                <div v-if="groupValue.component">
                    <CompositeField />
                    <FormItem v-bind="current.options[groupName].props">
                        <component
                            :is="current.options[groupName].component"
                            :name="groupName"
                            v-on="getFieldEvents(current.options[groupName].events)"
                            v-model="current.options[groupName].value"
                            v-bind="current.options[groupName].props"
                            @update-field="onUpdateFieldValue"
                            :disabled="disabledByHandler(current.options[groupName])"
                        />
                    </FormItem>
                </div>
                <template v-else>
                    <div
                        :key="groupName + '-' + key"
                        v-for="(field, key) in current.options[groupName]"
                        class="section-option-group__item-field"
                    >
                        <FormItem
                            v-if="
                                current.options[groupName][key].visibleCondition
                                    ? current.options[groupName][key].visibleCondition(current, getDevice)
                                    : true
                            "
                            :name="key"
                            v-bind="current.options[groupName][key].props"
                        >
                            <component
                                :is="field.component"
                                :name="key"
                                :group="groupName"
                                v-on="getFieldEvents(current.options[groupName][key].events)"
                                v-model="current.options[groupName][key].value"
                                v-bind="current.options[groupName][key].props"
                                :disabled="disabledByHandler(current.options[groupName], key)"
                                @update-field="onUpdateFieldValue"
                            />
                        </FormItem>
                    </div>
                </template>
            </div>
        </template>
        <div v-if="!current.required" class="option-list-sections__btns">
            <input-switch
                v-model="current.hidden"
                class="expansion__switch"
                size="small"
                text="builder.global.btns.hidden"
                @click.native.stop
            ></input-switch>
            <Button
                @click="copySectionId(current.id)"
                type="secondary"
                icon="copy"
                fullwidth
                class="option-list-sections__copy-id"
                title="Copy section ID"
            >
                {{ current.id }}
            </Button>
            <Button @click="dublicateSection" type="primary" icon="copy" fullwidth>
                {{ $t('builder.global.btns.dublicate') }}
            </Button>
            <Button @click="copySection(current)" type="primary" icon="copy" fullwidth>
                {{ $t('builder.global.btns.copy') }}
            </Button>
            <Button @click="removeSection(current.id)" type="secondary" icon="delete" fullwidth>
                {{ $t('builder.global.btns.remove') }}
            </Button>
        </div>
    </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import { debounce, cloneDeep } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import validateField from '@/helpers/validator';
import FormItem from '@/components/form/item.vue';
import InputAddress from '@/components/form/controls/InputAddress.vue';
import InputSwitch from '@/components/form/controls/InputSwitch';
import Button from '@/components/common/Button.vue';
import SectionsGroup from '@/components/builder/optionList/sections-group.vue';
import EventEmitter from '@/helpers/eventEmitter';
import CompositeField from '@/components/form/controls/CompositeField.vue';

export default {
    name: 'OptionListSections',
    components: {
        CompositeField,
        FormItem,
        InputAddress,
        InputSwitch,
        Button,
        SectionsGroup,
    },
    data() {
        return {
            groups: ['section', 'container', 'heading', 'content', 'columns', 'footer', 'button'],
            ignoreUpdate: false,
        };
    },
    computed: {
        ...mapState('builder', {
            isHistory: 'isHistory',
            activeSectionsGroup: 'activeSectionsGroup',
        }),
        ...mapGetters('builder', {
            current: 'getSidebarRightCurrent',
        }),
        ...mapGetters('globalVars', {
            getDevice: 'getDevice',
        }),
    },
    methods: {
        ...mapActions('builder', {
            addSection: 'addSection',
            updateSection: 'updateSection',
            removeSection: 'removeSection',
        }),
        ...mapActions('clipboard', {
            copyToClipboard: 'copyToClipboard',
        }),
        copySection(payload) {
            const section = cloneDeep(payload);
            section.id = 'fixture';
            section.items.forEach((item) => {
                item.id = 'fixture';
            });
            this.copyToClipboard({
                type: 'section',
                value: section,
            });
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: 'The section is copied',
            });
        },
        copySectionId(id) {
            this.copyToClipboard({
                type: 'ancor',
                value: id,
            });
            EventEmitter.trigger('show-noty', {
                type: 'success',
                text: 'The section id is copied',
            });
        },
        updateSectionDebounced: debounce(function(section) {
            this.updateSection(section);
        }, 500),
        dublicateSection() {
            const section = cloneDeep(this.current);
            section.id = uuid.v4();
            if (section?.options?.heading?.title)
                section.options.heading.title.value = '[Clone] ' + section.options.heading.title.value;
            section.items = section.items.map((item) => {
                item.id = uuid.v4();
                return item;
            });
            this.addSection(section);
        },
        getFieldEvents(events) {
            if (!events) return {};
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.current);
            }
            return fieldEvents;
        },
        disabledByHandler(obj, key = null) {
            const field = key ? obj[key].props.disabledBy : obj.props.disabledBy;
            if (!field) return false;
            const keys = field.split('.');
            const disabledByField = this.getObjByKeys(keys);
            const useDefaultMod = field.includes('useDefault');
            if (!disabledByField) return false;
            const value = useDefaultMod ? disabledByField.value : !disabledByField.value;
            return disabledByField ? value : false;
        },
        getObjByKeys(keys) {
            let value = this.current.options;
            if (!Object.keys(this.current.options).length) return;
            keys.forEach((key) => (value = value[key]));
            return value;
        },
        onUpdateFieldValue: debounce(function(payload) {
            const { name, group } = payload;
            // console.log('onUpdateFieldValue', { name, group });
            const option = group ? this.current.options?.[group]?.[name] : this.current.options?.[name];
            if (!option) return;
            let errors = [];
            const isValueArray = option.type === 'array' && Array.isArray(option.value);
            if (isValueArray) errors = option.value.map((value) => validateField(value, option.props.rules));
            else errors = validateField(option.value, option.props?.rules);
            try {
                if (group) this.$set(this.current.options[group][name].props, 'errors', errors);
                else this.$set(this.current.options[name].props, 'errors', errors);
                this.$set(this.current, 'hasErrors', this.current.checkError());
            } catch (error) {
                // костыль
            }
        }, 300),
    },
    watch: {
        current: {
            // Tracking the need to update the section
            handler(section, old) {
                // Ignore if the active section has changed
                if (this.ignoreUpdate) return;
                if (section?.id !== old?.id) {
                    this.ignoreUpdate = true;
                    setTimeout(() => {
                        this.ignoreUpdate = false;
                    }, 200);
                    return;
                }
                if (!old?.id) return;
                if (this.isHistory) this.updateSectionDebounced(section);
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.option-list-sections {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    &__btns {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 12px 0 12px;
    }
    &__copy-id {
        .btn__icon + span {
            font-size: 10px;
        }
    }
}

.section-option-group {
    margin: 0 0 $spacer * 8;

    &__heading {
        margin: 0 0 $spacer * 5;
    }

    &__item {
        &-field {
            margin: 0 0 $spacer * 4;

            &:last-child {
                margin: 0;
            }
        }
    }
}
</style>
